import { shareSNS } from './_share.js';
import { Scroll, ScrollToTop } from './lib/_scroll.js';
var $html = document.documentElement;
export var common = {
	scrl: "",
	beforeW: 0,
	canClick: true,
	init: ()=>{
		common.start();
	},
	start: function(){
		let resizeFlag;
		let resizeTimer = null;
		let $body = document.getElementsByTagName('body')[0];
		
		common.resize();
		common.nav();
		common.scrl = new Scroll();
		setTimeout(function(){
			$body.classList.remove("disable");
		}, 200);
		/* window resize
		----------------------------- */
		var resizeCallback = function() {
			common.resize();
			resizeTimer = null;
		};
		window.addEventListener("resize", function(){
			if( !$body.classList.contains("resize") ){
				$body.classList.add("resize");
			}
			if(resizeTimer) {
				clearTimeout(resizeTimer);
			}
			resizeTimer = setTimeout(resizeCallback, 300);
		}, false);
	},
	nav: ()=>{
		shareSNS.nav();
		//console.log("common nav");
		let pageScroll = function(e){
			//e.preventDefault();
			let id 		= this.getAttribute("data-id");
			console.log(id);
			let posY 	= document.querySelector("#" + id).getBoundingClientRect().top;
			console.log(posY);
			ScrollToTop.start(posY, 1000);
		}
		let toggleMenu = function(e){
			if(common.canClick){
				common.canClick = false;
				if( !$html.classList.contains("menu") ){
					$html.classList.add("menu");
				}else{
					$html.classList.remove("menu");
				}
				setTimeout(function(){
					common.canClick = true;
				}, 100);
			}
		}
		let $btnMenu = document.querySelector("#btn_menu");
		$btnMenu.removeEventListener("click", toggleMenu, false);
		$btnMenu.addEventListener("click", toggleMenu, false);
		
		//let $btnPageTop= document.querySelector(`[data-func-pagetop]`);
		//$btnPageTop.removeEventListener("click", pageScroll, false);
		//$btnPageTop.addEventListener("click", pageScroll, false);
	},
	resize: ()=>{
		var winW 	= window.innerWidth;
		var winH 	= window.innerHeight;
		let $body 	= document.getElementsByTagName('body')[0];
		
		addressbarH();
		
		setTimeout(function(){
			if( $body.classList.contains("resize") ){
				$body.classList.remove("resize");
			}
		},300);
		function addressbarH(){
			if(common.beforeW != winW){
				// 100vh、アドレスバーが出てる時に対応
				let vh = window.innerHeight * 0.01;
				document.documentElement.style.setProperty("--vh", `${vh}px`);
			}
		}
		common.beforeW = winW;
	},
	mouseCursor: function(){
		window.document.addEventListener("mousemove", getMousePos, false);
		function getMousePos(e){
			mouse[0] = e.clientX;
			mouse[1] = e.clientY;
		}
	}
}
