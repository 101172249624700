import { preload } from './lib/_preload.js';
import { common } from './_common.js';

export class Framework {
	constructor(){
		this.firstVisit = true;
		this.canClick 	= true;
		this.pageinfo = {
			page: document.body.getAttribute("data-page"),
			path: "",
			param: "",
			pathArry: [],
			paramArry: []
		}
		this.nextpage = "";
		this.checkURL();
		//console.log(this.pageinfo);
	}
	
	checkURL(){
		if(this.canClick){
			this.canClick = false;
			//path
			this.pageinfo.path		= location.pathname;
			this.pageinfo.pathArry 	= this.pageinfo.path.split("/");
			//パラメータ
			this.pageinfo.param 	= location.search.substring(1),
			this.pageinfo.paramArry	= this.pageinfo.param.split("&");
			let hasParam = false;
			for (let i = 0; this.pageinfo.paramArry[i]; i++) {
				let kv = this.pageinfo.paramArry[i].split("=");
			}
			this.nextPage = this.pageinfo.path;
			this.changeContent();
		}
	}
	
	nav(){
		
	}
	
	changeURL(){
		
	}
	
	changeContent(){
		this.afterChangePage();
	}
	
	afterChangePage(){
		if(this.firstVisit){
			this.firstVisit = false;
			common.init();
		}
		/*
		let imgArry = [
			"/assets/images/a.jpg",
			"/assets/images/b.jpg"
		];
		preload.startLoad(imgArry).then(res => {
		});
		*/
		common.resize();
		common.nav();
		this.reset();
		this.nav();
	}
	
	reset(){
		this.canClick = true;
	}
}