import { getEasing } from '_easing.js';
	
let Block = {
	top: 0,
	show: 0
}
let Animate = {
	top: 0,
	show: 0
}
export class Scroll {
	constructor(){
		this.topD = 0;
		this.btmD = this.topD + window.innerHeight;
		this.animations = new Array();
		this.blocks 	= new Array();
		
		this.checkBlock();
		this.checkScroll();
		document.addEventListener("scroll", ()=>{
			if ('scrollingElement' in document) {
				this.topD = document.scrollingElement.scrollTop;
			} else {
				this.topD = document.body.scrollTop;
			}
			this.checkScroll();
		}, false);
	}
	
	checkScroll(){
		this.btmD = this.topD + window.innerHeight;
		for(let index in this.animations){
			if(this.btmD >= this.animations[index].show){
				this.activeAnimate(index);
			}
		}
	}
	activeAnimate(i){
		let $a = this.blockA[i];
		if( !$a.classList.contains("animate") ){
			$a.classList.add("animate");
			setTimeout(function(){
				$a.classList.add("animated");
			}, 1000);
		}
	}
	//アニメーションのブロックをチェック
	checkBlock(){
		this.blockA = document.querySelectorAll(".a");
		this.animations = [];
		for(let i = 0; i < this.blockA.length; i++){
			let _a = object(Animate);
			_a.top 	= Math.floor( this.blockA[i].offsetTop );
			_a.show = Math.floor( _a.top - window.innerHeight / 3 );
			this.animations.push(_a);
		}
	}
}
export let ScrollToTop = {
	begin: 0,
	yOffset: 0,
	end: 0,
	timer: null,
	start: function(y, duration){
		let $this = ScrollToTop;
		$this.end = y;
		//console.log(y, duration);
		$this.begin = new Date() - 0;
		$this.yOffset = window.pageYOffset;
		console.log($this.yOffset, y);
		
		clearInterval($this.timer);
		let ease = getEasing("easeInOutCubic");
		$this.timer = setInterval( ()=> {
			let current = new Date() - $this.begin;
			if(current >= duration){
				current = duration;
				clearInterval($this.timer);
			}
			let progress = 1 - current/duration;
			let easeProgress = ease(progress, 0, 1, 1);
			//console.log(easeProgress);
			window.scrollTo(0, $this.yOffset*easeProgress );
		}, 10);
	}
}